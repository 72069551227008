export default {
    UserPoolId: 'ca-central-1_OUfvURZ2F', // replace with your User Pool ID
    ClientId: 'fvej8gc1r8hu68e5qnb91e8k1', // replace with your Client ID
    AppDomain: 'https://mvp-careerfit.auth.ca-central-1.amazoncognito.com', // replace with your App Domain
    RedirectUri: 'http://localhost:8082/login/oauth2/code/cognito', // replace with your Redirect Uri
    SignoutUri: 'http://localhost:8082/logout', // replace with your Redirect Uri
    AppUrl: 'http://localhost:8082', // replace with your App Url
  };


