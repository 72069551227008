<template>
  <v-main style="background-color: #edf8ff;">
    <div class="custom-container">
      <h1 class="display-1 text-center mb-10">It's as easy as...</h1>
      <v-row align="center" class="mt-20">
        <v-col cols="4" class="text-center">
          <v-icon size="175" color="grey">mdi-numeric-1-circle</v-icon>
        </v-col>
        <v-col cols="8">
          <h3 class="text-header">Find Out What's Out There</h3>
          <p class="icon-description">
            The canadian healthcare market is ever changing, are you familiar with all the available options? CareerFit divides the entire health sector into 16 job families. Within these 16 job families, there are 600+ job titles. Navigate through the job families to find the job titles that interest you. 
          </p>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-5">
        <v-col cols="12">
          <v-divider class="vertical-divider"></v-divider>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-20">
        <v-col cols="4" class="text-center">
          <v-icon size="175" color="grey">mdi-numeric-2-circle</v-icon>
        </v-col>
        <v-col cols="8">
          <h3 class="text-header">Get the Right Education</h3>
          <p class="icon-description">
            Obtaining the right education is key to success in healthcare. This includes enrolling in accredited programs, obtaining necessary certifications, and continuously updating knowledge through ongoing education. It's important to stay current with the latest developments and advancements in the field to provide the best care possible.
          </p>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-5">
        <v-col cols="12">
          <v-divider class="vertical-divider"></v-divider>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-20">
        <v-col cols="4" class="text-center">
          <v-icon size="175" color="grey">mdi-numeric-3-circle</v-icon>
        </v-col>
        <v-col cols="8">
          <h3 class="text-header">Gain Practical Experience</h3>
          <p class="icon-description">
            Practical experience is invaluable in healthcare. This includes internships, residencies, and hands-on training in real-world settings. Practical experience allows for the application of theoretical knowledge, the development of essential skills, and the ability to handle real-life scenarios effectively. It also enhances problem-solving abilities and confidence.
          </p>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'DefaultLayout',
  setup() {
    const router = useRouter();
    const activeTab = ref(null);

    const goToLogin = () => {
      router.push({ name: 'UserLogin' });
    };

    const goToFormWizard = () => {
      router.push({ name: 'UserFormWizard' });
    };

    const resetActiveTab = () => {
      activeTab.value = null;
    };

    watchEffect(() => {
      if (router.currentRoute.value.path === '/') {
        resetActiveTab();
      }
    });

    return {
      activeTab,
      goToLogin,
      goToFormWizard,
      resetActiveTab,
    };
  },
};
</script>

<style scoped>
h1 {
  font-weight: bold;
  margin-bottom: 20px;
}

h3.text-header {
  font-weight: bold;
  margin-bottom: 10px;
}

h4 {
  margin-bottom: 20px;
}

.custom-container {
  padding-left: 100px;
  padding-top: 50px;
  padding-right: 100px;
}

.custom-col {
  max-width: 100%;
}

.subtitle-1 {
  color: #6c757d;
}

v-img {
  max-width: 100%;
  height: auto;
}

.mt-20 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 20px;
}

.icon-header {
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.icon-description {
  margin-top: 8px;
  font-size: 16px;
  color: #6c757d;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.content-box {
  min-height: 300px;
}

</style>