<template>
  <v-main style="height: 70vh; margin-top: 150px;">
    <v-container style="width: 100%; max-width: 1000px;">
      <v-card style="width: 100%;">
        <v-stepper v-model="step" :items="stepper" show-actions>
          <template v-slot:actions="{ next, prev }">
            <v-row no-gutters justify="space-between" class="px-3">
              <v-col cols="2">
                <v-btn color="primary" @click="prev" :disabled="step === 1" block class="mb-3">
                  Back
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" @click="step === 4 ? submit() : next()" block class="mb-3">
                  {{ step === 4 ? 'Submit' : 'Next' }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.1`]>
            <div>
              <h3 class="text-h6">Select your interested career families</h3>
              <br>
              <v-chip-group v-model="selectedJobFamilies" column multiple>
                <v-chip v-for="(family, index) in jobFamilies" :key="index" filter variant="outlined" color="primary">
                  {{ family }}
                </v-chip>
              </v-chip-group>
              <v-switch
                v-model="selectAllJobFamilies"
                label="Select All"
                color="primary"
                style="padding-left: 10px;"
                @change="toggleSelectAllJobFamilies"
              ></v-switch>
            </div>
          </template>
          <template v-slot:[`item.2`]>
            <h3 class="ml-3 text-h6">Select your preferred income</h3>
            <br>
            <div class="text-h5 ml-3">
              {{ isHourly ? `CAD $${(salary / 2080).toFixed(0)}/hr` : `CAD $${salary}/yr` }}
            </div>
            <br>
            <v-slider class="ml-6 mr-6" v-model="salary" step="1000" :max="150000" :min="20000"></v-slider>
            <v-switch color="primary" v-model="isHourly" label="Hourly Rate" class="ml-3"></v-switch>
          </template>
          <template v-slot:[`item.3`]>
            <div style="padding-bottom: 25px;">
              <h3 class="text-h6 ml-3">Select your job prospects</h3>
              <br>
              <v-chip-group v-model="selectedJobProspects" class="ml-3" style="padding-top: 40px;" multiple filter variant="outlined" color="primary">
                <v-chip v-for="(prospect, index) in jobProspects" :key="index" :value="prospect" color="primary" text-color="white">
                  {{ prospect }}
                </v-chip>
              </v-chip-group>
              <v-switch
                v-model="selectAllJobProspects"
                label="Select All"
                color="primary"
                style="padding-left: 10px;"
                @change="toggleSelectAllJobProspects"
              ></v-switch>
            </div>
          </template>
          <template v-slot:[`item.4`]>
            <div>
              <h3 class="text-h6">Select to include regulatory bodies</h3>
              <br>
              <br>
              <v-switch v-model="includeRegulated" color="primary" label="Include Regulated Professions" class="ml-3"></v-switch>
              <div style="height: 85px; padding-top: 10px;">
                <v-fade-transition>
                  <v-slider v-if="includeRegulated" v-model="timeToFirstJob" class="ml-6 mr-6" step="1" show-ticks="always" thumb-label="always" :ticks="tickLabels" tick-size="4" :max="48" :min="0"></v-slider>
                </v-fade-transition>
              </div>
            </div>
          </template>
          <template v-slot:[`item.5`]>
            <div>
              <h3 class="text-h6">Response Data</h3>
              <pre>{{ JSON.stringify(response, null, 2) }}</pre>
            </div>
          </template>
        </v-stepper>
      </v-card>
    </v-container>
    <v-dialog v-model="showOverlay" :width="contactSuccess ? '400px' : '800px'">
      <v-card style="border-radius: 16px;">
        <v-container>
          <v-row justify="end" no-gutters>
            <v-col cols="12" class="text-right" style="padding-top: 8px; padding-right: 8px;">
              <v-icon large color="grey" @click="showOverlay = false" style="cursor: pointer;">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-row class="align-center justify-center" style="height: 100%;">
            <v-col cols="12" class="success-message" v-if="contactSuccess">
              <v-icon class="success-icon" color="green" style="font-size: 80px;">mdi-check-circle</v-icon>
              <p class="success-text">Success</p>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="4" class="d-flex align-center justify-center text-center">
                  <div>
                    <v-card-title class="headline">Careers Found</v-card-title>
                    <v-card-text>
                      <div class="text-h1">{{ animatedCounter }}</div>
                    </v-card-text>
                  </div>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="8">
                  <v-card-text>
                    <v-form ref="form">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="firstName" label="First Name" required variant="solo-filled"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="lastName" label="Last Name" required variant="solo-filled"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field v-model="email" label="E-mail" required variant="solo-filled"></v-text-field>
                      <v-textarea v-model="message" label="Your Message" required variant="solo-filled"></v-textarea>
                      <v-btn :loading="btnloading" block variant="tonal" large rounded="lg" color="primary" @click="submitContact">
                        Contact Us
                      </v-btn>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { ref, computed } from 'vue';
import instance from '../store/axios';
import { jobProspects, stepper, jobFamilies, preferences } from '../config/forms.js';

export default {
  setup() {
    const step = ref(1);
    const selectedJobFamilies = ref([]);
    const includeRegulated = ref(true);
    const salary = ref(20000);
    const isHourly = ref(false);
    const selectedJobProspects = ref([]);
    const timeToFirstJob = ref(0);
    const loading = ref(false);
    const btnloading = ref(false);
    const response = ref(null);
    const showOverlay = ref(false);
    const showForm = ref(false);
    const responseCount = ref(0);
    const animatedCounter = ref(0);
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const message = ref('');
    const contactSuccess = ref(false);

    const selectAllJobFamilies = ref(false);
    const selectAllJobProspects = ref(false);

    const nextButtonLabel = computed(() => step.value === 4 ? 'Submit' : 'Next');

    const animateCounter = (finalCount) => {
      let currentCount = 0;
      clearInterval(animationInterval.value);
      animationInterval.value = setInterval(() => {
        currentCount += Math.ceil(finalCount / 100);
        if (currentCount >= finalCount) {
          currentCount = finalCount;
          clearInterval(animationInterval.value);
        }
        animatedCounter.value = currentCount;
      }, 20);
    };

    const animationInterval = ref(null);

    // Toggle Select All Job Families
    const toggleSelectAllJobFamilies = () => {
      if (selectAllJobFamilies.value) {
        selectedJobFamilies.value = jobFamilies.map((_, index) => index);
      } else {
        selectedJobFamilies.value = [];
      }
    };

    // Toggle Select All Job Prospects
    const toggleSelectAllJobProspects = () => {
      if (selectAllJobProspects.value) {
        selectedJobProspects.value = jobProspects.map((prospect) => prospect);
      } else {
        selectedJobProspects.value = [];
      }
    };

    const submit = async () => {
      loading.value = true;
      showOverlay.value = true;
      animatedCounter.value = 0;
      animateCounter(100);

      const data = {
        selectedJobFamilies: selectedJobFamilies.value.map(index => jobFamilies[index]),
        selectedJobProspects: selectedJobProspects.value,
        salary: salary.value,
        isHourly: isHourly.value,
        includeRegulated: includeRegulated.value,
        timeToFirstJob: timeToFirstJob.value,
      };

      console.log('Data:', JSON.stringify(data));

      try {
        const res = await instance.get('public', {
          params: data,
          headers: {
            'Content-Type': 'application/json'
          }
        });

        response.value = res.data;

        console.log('API Response:', JSON.stringify(response.value, null, 2));

        if (response.value && response.value.count !== undefined) {
          responseCount.value = response.value.count;
          animateCounter(responseCount.value);
        }

        console.log('Response Count:', responseCount.value);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        loading.value = false;
      }
    };

    const viewDetails = () => {
      showForm.value = true;
    };

    const submitContact = async () => {
      btnloading.value = true; // Start loading
      const contactdata = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        message: message.value,
      };

      await putData(contactdata);

      btnloading.value = false; // Stop loading
    };

    const putData = async (data) => {
      try {
        await instance.put('public/contact', { 
          contactinfo : data
        });
        console.log('Data successfully submitted:', data);
        contactSuccess.value = true;
      } catch (error) {
        console.error('Error submitting data:', error);
        contactSuccess.value = false;
      }
    };

    return {
      step,
      selectedJobFamilies,
      selectedJobProspects,
      salary,
      isHourly,
      nextButtonLabel,
      submit,
      stepper,
      jobFamilies,
      jobProspects,
      preferences,
      includeRegulated,
      timeToFirstJob,
      tickLabels: {
        0: 'ASAP',
        12: '1 Year',
        24: '2 Years',
        36: '3+ years',
      },
      loading,
      response,
      showOverlay,
      showForm,
      responseCount,
      animatedCounter,
      firstName,
      lastName,
      email,
      message,
      btnloading,
      contactSuccess,
      viewDetails,
      submitContact,
      selectAllJobFamilies,
      toggleSelectAllJobFamilies,
      selectAllJobProspects,
      toggleSelectAllJobProspects,
    };
  },
};
</script>
<style scoped>
.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}

.success-icon {
  font-size: 60px;
}

.success-text {
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
}
</style>