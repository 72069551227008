<template>
  <v-row class="pa-5">
    <v-col v-for="(job, index) in jobs" :key="index">
      <v-card fill-height class="pa-7 card-size d-flex flex-column">
        <v-card-title>{{ job.Occupation }}</v-card-title>
        <v-card-subtitle>Job Demand here</v-card-subtitle>
        <v-card-text class="mb-auto">{{ job.Description }}</v-card-text>
        <v-card-actions class="justify-space-between">
          <v-btn color="primary" variant="elevated" small text>View Details</v-btn>
          <v-btn color="primary" small text>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useUserStore }  from '../store/store';
import instance from '../store/axios';

export default {
  setup() {
    const jobs = ref([]);
    const userStore = useUserStore();

    onMounted(async () => {
      try {
        const response = await instance.get('search/results', {
          params: userStore.userData,
          headers: {
            'Content-Type': 'application/json'
          }
        });

        jobs.value = response.data.map(job => ({
          Occupation: job.Occupation,
          Description: job.Description,
          // Add other properties as needed...
        }));
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    });

    return { jobs };
  }
}
</script>