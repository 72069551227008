<template>
    <v-row class="pa-5">
        <v-col v-for="(item, index) in items" :key="index">
          <v-card fill-height class="pa-7 card-size d-flex flex-column">
            <v-card-title>{{ item.title }}</v-card-title>
            <v-card-subtitle>Job Demand here</v-card-subtitle>
            <v-card-text class="mb-auto">{{ item.description }}</v-card-text>
            <v-card-actions class="justify-space-between">
                <v-btn color="primary" variant="elevated" small text>View Details</v-btn>
                <v-btn color="primary" small text>Save</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>
import { ref } from 'vue';
  export default {
    setup() {
      const items = ref([
        // Replace this with your API response
        { title: 'Job 1', description: 'This is a job in clinical research' },
        { title: 'Job 2', description: 'This is a job in dentistry' },
        { title: 'Job 3', description: 'This is a job in nursing' },
        { title: 'Job 4', description: 'This is a job in medicine' },
        { title: 'Job 5', description: 'This is a job in pharmacy' },
        { title: 'Job 6', description: 'This is a job in physical therapy' },
        { title: 'Job 7', description: 'This is a job in occupational therapy' },
        { title: 'Job 8', description: 'This is a job in speech therapy' },
        { title: 'Job 9', description: 'This is a job in nutrition' },
        { title: 'Job 10', description: 'This is a job in public health' },
        { title: 'Job 11', description: 'This is a job in health administration' }
        
        // ...
      ]);
  
      return {
        items
      };
    }
  }
  </script>
