import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import UserLayout from '../layouts/UserLayout.vue';
import UserLogin from '../views/UserLogin.vue';
import HomeSplash from '../layouts/HomeSplash.vue';
import HowItWorks from '../layouts/HowItWorks.vue';
import AboutUs from '../layouts/AboutUs.vue';

import UserFormWizard from '../views/UserProfileForm.vue';
import LogoutSuccess from '../views/LogoutSuccess.vue';
import UserProfile from '../views/UserProfile.vue';
import UserProfile2 from '../views/UserProfileForm2.vue';
import UserResults from '../views/UserResults.vue';
import UserSettings from '../views/UserSettings.vue';
import UserCoaching from '../views/UserCoaching.vue';
import UserCoachingDetails from '../views/UserCoachingDetails.vue';
import UserSaved from '../views/UserSaved.vue';
import UserDashboard from '../views/UserDashboard.vue';
import UserFeedBack from '../views/UserFeedBack.vue';
import JobDetails from '../views/JobDetails.vue';
import { authenticated, logout } from '../store/auth2';

async function requireAuth(to, from, next) {
  console.log('Checking Auth...');
  const isAuthenticated = await authenticated();
  if (!isAuthenticated) {
    console.log('not authenticated - redirecting to login')
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    console.log('authenticated')
    next();
  }
}

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'HomeSplash',
        component: HomeSplash,
      },
      {
        path: '/public',
        name: 'UserFormWizard',
        component: UserFormWizard,
      },
      {
        path: '/howitworks',
        name: 'HowItWorks',
        component: HowItWorks,
      },
      {
        path: '/aboutus',
        name: 'AboutUs',
        component: AboutUs,
      }
    ]
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/dashboard',
    name: 'UserLayout',
    component: UserLayout,
    children: [
      {
        path: '/dashboard',
        component: UserDashboard
      },
      {
        path: '/profile',
        component: UserProfile
      },
      {
        path: '/settings',
        component: UserSettings
      },
      {
        path: '/search',
        component: UserProfile2
      },
      {
        path: '/results',
        component: UserResults,
      },
      {
        path: '/:jobId',
        name: 'JobDetails',
        component: JobDetails,
        props: true
      },
      {
        path: '/coaching',
        component: UserCoaching,
        children: [
          {
            path: '/:sessionId',
            name: 'UserCoachingDetails',
            component: UserCoachingDetails,
            props: true
          },
        ],
      },
      {
        path: '/saved',
        component: UserSaved,
      },
      {
        path: '/feedback',
        component: UserFeedBack,
      },
    ],
    beforeEnter: requireAuth
  },
  {
    path: '/logoutSuccess',
    component: LogoutSuccess,
    beforeEnter: async (to, from, next) => {
      await logout();
      next({
        path: '/login'
      });
    }
  }
  // add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;