<template>
  <v-card class="ma-5 pa-3" rounded-lg elevation="2">
    <v-card-title>Change User Settings</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <h3>Change Name</h3>
        </v-col>
        <v-col cols="6">
          <v-form @submit.prevent="save">
            <v-text-field label="First Name" v-model="user.firstName" />
            <v-text-field label="Middle Name" v-model="user.middleName" />
            <v-text-field label="Last Name" v-model="user.lastName" />
            <v-btn type="submit" color="primary" class="float-right">Save</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, onMounted } from 'vue';
import instance from '../store/axios';


export default {
  setup() {
    const user = ref({
      firstName: '',
      middleName: '',
      lastName: '',
    });

    const save = () => {
      // Save the user data here
      console.log(user.value);
    };

    onMounted(async () => {
    try {
      const response = await instance.get('profile/settings', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      user.value = response.data;
      } 
      catch (error) {
        console.error('Error:', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
        }
      }
    });

    return {
      user,
      save,
    };
  },
};
</script>