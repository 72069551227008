<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>
.v-theme--myTheme .v-theme--primary {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #000000 !important; /* Change this to the color you want for the text */
}
</style>
