<template>
  <v-app-bar app fixed>
    <v-toolbar-title>My Coaching</v-toolbar-title>
    <v-tabs v-model="selectedTab" grow>
      <v-tab v-for="(tab, index) in tabs" :key="index" @click="handleTabChange(tab)">{{ tab }}</v-tab>
    </v-tabs>
  </v-app-bar>
  <v-navigation-drawer v-model="drawerVisible" width="370">
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-list v-else>
      <v-list-item v-for="(item, index) in currentContent" :key="index">
        <v-btn text width="100%" :to="{ name: 'UserCoachingDetails', params: { sessionId: item.id, } }" class="justify-start">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <router-view></router-view> <!-- Add this line -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useFormStore } from '../store/store';
import axios from '../store/axios';

export default {
  setup() {
    const selectedTab = ref(0); // 'Group' is now the first tab
    const tabs = ref(['Group', 'Individual']); // Removed 'Enrolled'
    const currentContent = ref();
    const drawerVisible = ref(true);
    const isLoading = ref(true);
    const formStore = useFormStore();


    const fetchForms = async (tab) => {
      console.log(`Fetching forms for ${tab}`);
      if (tab === 'Group' && (!formStore.groupCoach || formStore.groupCoach.length === 0)) {
        try {
          console.log(`Forms for ${tab} dont exist, calling API`);
          const response = await axios.get('coaching', { params: { tab } });
          console.log(response.data);
          formStore.setGroupCoach(response.data);
        } catch (error) {
          console.error(`Error fetching ${tab} forms:`, error);
        }
      } else if (tab === 'Individual' && (!formStore.individualCoach || formStore.individualCoach.length === 0)) {
        try {
          console.log(`Forms for ${tab} dont exist, calling API`);
          const response = await axios.get('coaching', { params: { tab } });
          formStore.setIndividualCoach(response.data);
        } catch (error) {
          console.error(`Error fetching ${tab} forms:`, error);
        }
      }
      console.log(`Forms for ${tab} exist, skipping`);
    };

    const handleTabChange = async (tab) => {
      selectedTab.value = tabs.value.indexOf(tab);
      if (tab === 'Group' || tab === 'Individual') {
        await fetchForms(tab);
        currentContent.value = tab === 'Group' ? formStore.groupCoach : formStore.individualCoach;
        drawerVisible.value = true;
      } else {
        currentContent.value = [];
        drawerVisible.value = false;
      }
    };

    onMounted(async () => {
      // Always fetch 'Group' and 'Individual' forms on mount
      await fetchForms('Group');
      await fetchForms('Individual');
      currentContent.value = formStore.groupCoach;
      isLoading.value = false;
    });

    return {
      selectedTab,
      tabs,
      currentContent,
      handleTabChange,
      drawerVisible,
      isLoading
    };
  },
};
</script>