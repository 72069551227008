import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { AuthenticationDetails, CognitoUser, AmazonCognitoIdentity } from 'amazon-cognito-identity-js';

import config from '../config/config';

const poolData = {
    UserPoolId: config.UserPoolId,
    ClientId: config.ClientId,
};
const userPool = new CognitoUserPool(poolData);

async function login(username, password) {
    return new Promise((resolve, reject) => {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: username,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve({ status: 'success', result, cognitoUser });
            },
            onFailure: (err) => {
                reject({ error: err, cognitoUser });
              },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log('New password required');
                console.log('cognitoUser in newPasswordRequired:', cognitoUser);
                resolve({ status: 'newPasswordRequired', userAttributes, requiredAttributes, cognitoUser });
            },
        });
    });
}
async function authenticated() {
    return new Promise((resolve, reject) => {
        let cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }
                if (session.isValid()) {
                    const idToken = session.getIdToken().getJwtToken();
                    resolve(idToken);
                } else {
                    cognitoUser.refreshSession(session.getRefreshToken(), async (err, session) => {
                        if (err) {
                            reject(err);
                        } else {
                            const isValid = session.isValid();
                            if (isValid) {
                                const idToken = session.getIdToken().getJwtToken();
                                resolve(idToken);
                            }
                            resolve(false);
                        }
                    });
                }
            });
        }
        else {
            const IdToken = localStorage.getItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.idToken`);
            const AccessToken = localStorage.getItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.accessToken`);
            const RefreshToken = localStorage.getItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.refreshToken`);
            if (IdToken && AccessToken && RefreshToken) {
                const sessionData = {
                    IdToken: new AmazonCognitoIdentity.CognitoIdToken({IdToken}),
                    AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({AccessToken}),
                    RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken})
                };
                const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);
                cognitoUser = userPool.getCurrentUser();
                cognitoUser.setSignInUserSession(session);
                resolve(session.isValid() ? IdToken : false);
            } else {
                resolve(false);
            }
        }
    });
}

function forcechangePasswordSubmit(cognitoUser, newPassword, sessionUserAttributes) {
    console.log('cognitoUser in forcechangePasswordSubmit:', cognitoUser);
    return new Promise((resolve, reject) => {
        console.log('reset password submit');
        cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
            onSuccess: (result) => {
                console.log('login - password changed successfully', result);
                cognitoUser.globalSignOut({
                    onSuccess: () => {
                      console.log('User has been signed out from all sessions.');
                      resolve();
                    },
                    onFailure: (err) => {
                      console.log('Failed to sign out user from all sessions:', err);
                      reject(err);
                    }
                  });
            },
            onFailure: (error) => {
                console.log('login - password change error', error);
                reject(error);
            }
        });
    });
}

function sendCode(cognitoUser) {
    console.log('send code');
    cognitoUser.forgotPassword({
        onSuccess: function (result) {
            console.log('call result: ' + result);
        },
        onFailure: function(err) {
            console.log('call error: ' + err);
        },
        inputVerificationCode() {
            console.log('inputVerificationCode');
        }
    });
}

function resetPasswordSubmit(cognitoUser, code, newPassword) {
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          console.log('Password reset successful');
          cognitoUser.globalSignOut({
            onSuccess: () => {
              console.log('User has been signed out from all sessions.');
              resolve();
            },
            onFailure: (err) => {
              console.log('Failed to sign out user from all sessions:', err);
              reject(err);
            }
          });
        },
        onFailure(err) {
          console.log('Password reset failed', err);
          reject(err);
        },
      });
    });
  }

function logout() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
        cognitoUser.signOut();
        localStorage.removeItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.idToken`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.accessToken`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.<ClientId>.<User>.refreshToken`);
        sessionStorage.removeItem('cf_saved');
        sessionStorage.removeItem('cf_search');
        sessionStorage.removeItem('userdata');
    }
}

export {
    authenticated,
    forcechangePasswordSubmit,
    sendCode,
    resetPasswordSubmit,
    login,
    logout
};