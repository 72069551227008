<template>
  <div>
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center" >
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <v-row>
        <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2">
          <v-row >
            <v-col cols="8" class="px-12 pt-8 mb-20">
              <h1 class="display-2 text-primary">
                {{ username ? `Welcome back, ${username}` : 'Welcome to CareerFit.ca!' }}
              </h1>
              <p class="subtitle-1">
                View your enrolled sessions, saved jobs and more below!
              </p>
            </v-col>
            <v-col cols="4" class="px-10 pt-8 mb-12">
              <v-card class="pa-5 text-center">
                <h2>Your saved CareerFits: <span class="text-primary">{{ totalCareerFits.value }}</span></h2>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-10 mt-8">
              <h3 class="text-left mb-5">Your Last Search:</h3>
              <v-card v-if="totalSelectedJobFamilies !== 0" class="pa-5">
                <v-row>
                  <v-col cols="3" class="text-center">
                    <p>Total Selected Job Families:</p>
                    <p class="text-primary">{{ totalSelectedJobFamilies }}</p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="3" class="text-center">
                    <p>Job Prospects:</p>
                    <p class="text-primary">{{ jobProspects.join(', ') }}</p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="2" class="text-center">
                    <p>Time to First Job:</p>
                    <p class="text-primary">{{ timeToFirstJob }}</p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="2" class="text-center">
                    <p>Regulated:</p>
                    <p class="text-primary">{{ regulated }}</p>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="2" class="text-center">
                    <p>Min Salary:</p>
                    <p class="text-primary">{{ salary }}</p>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-else class="pa-5 text-center">
                No CareerFit search found, click New Search to get started!
              </v-card>
              <div class="text-end mt-6">
                <v-btn variant="tonal" color="primary" class="mr-4" @click="$router.push('/search')">New Search</v-btn>
                <v-btn color="primary" :disabled="totalSelectedJobFamilies === 0" @click="$router.push('/results')">View Results</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-10 mt-12">
              <h3 class="text-left mb-5">Enrolled CareerFit Coaching:</h3>
              <v-card class="pa-5">
                <v-data-table
                  :headers="headers"
                  :items="courses"
                  no-data-text="Not enrolled in any courses yet.">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.course }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.time }}</td>
                    </tr>
                  </template>
                  <template v-slot:bottom> </template>
                </v-data-table>
              </v-card>
              <div class="text-end mt-4">
                <v-btn color="primary" to="/coaching">View Courses</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-10 mt-12">
              <h3 class="text-left mb-5">Recommended for You:</h3>
              <v-card class="pa-5 d-flex justify-center align-center">
                <p class="text-center text--darken-2">Coming Soon</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useUserStore } from '../store/store';
import instance from '../store/axios';
import router from '../router/router';

export default {

  setup() {
    const username = ref('');
    const totalCareerFits = ref('');
    const isLoading = ref(true);
    const totalSelectedJobFamilies = ref(0);
    const jobProspects = ref([]);
    const timeToFirstJob = ref(0);
    const salary = ref(0);
    const userStore = useUserStore();
    const regulated = ref('');
    
    const goToResults = () => {
      const cf_search = sessionStorage.getItem('cf_search');
      router.push({ name: 'Results', params: { cf_search } });
    };

    const checkUserData = () => new Promise(resolve => {
      const userdata = sessionStorage.getItem('userdata');
      const cf_search = JSON.parse(sessionStorage.getItem('cf_search'));
      if (userdata) {
        username.value = JSON.parse(userdata).firstName;
        totalCareerFits.value = ref(JSON.parse(sessionStorage.getItem('cf_saved')).length);
        totalSelectedJobFamilies.value = cf_search.selectedJobFamilies.length;
        jobProspects.value = cf_search.selectedJobProspects;
        timeToFirstJob.value = cf_search.timeToFirstJob;
        salary.value = cf_search.salary;
        regulated.value = cf_search.includeRegulated;
        resolve();
      }
    });

    const courses = computed(() => {
      return Object.entries(userStore.cf_coaching).map(([ , [ time , date, status, name]]) => ({
        course: name,
        status: status,
        date: date,
        time: time
      }));
    });

    const headers = [
      { title: 'Course Name', key: 'course' },
      { title: 'Status', key: 'status' },
      { title: 'Date', key: 'date' },
      { title: 'Time', key: 'time' }
    ];

    const fetchEnrolled = async () => {
      if (userStore.cf_coaching && Object.keys(userStore.cf_coaching).length > 0) {
        return;
      }

      try {
        const response = await instance.get('coaching/enroll', {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        userStore.setEnrolledCoach(response.data.cf_coaching);
      } catch (error) {
        console.error(`Error fetching enrolled data:`, error);
      }
    };

    


    onMounted(async () => {
      await checkUserData();
      await fetchEnrolled();
      isLoading.value = false;
    });

    return {
      username,
      isLoading,
      totalCareerFits,
      totalSelectedJobFamilies,
      jobProspects,
      timeToFirstJob,
      goToResults,
      fetchEnrolled,
      userStore,
      headers,
      courses,
      salary,
      regulated
    }
  }
}
</script>

<style scoped>
.text-primary {
  color: var(--v-primary-base);
}
</style>