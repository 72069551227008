import axios from 'axios';
import { authenticated } from './auth2';

const instance = axios.create({
    baseURL: 'mvp'
});

async function updateAuthorizationToken(token) {
    if (token) {
        instance.defaults.headers.common['Authorization'] = token;
    }
}

authenticated().then(token => {
    if (token) {
        updateAuthorizationToken(token);
    }
});

export default instance;

export {
    updateAuthorizationToken
};