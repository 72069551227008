<template>
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          size="70"
          width="7"
          color="primary">
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <v-row class="pa-5 justify-center">
    <v-col v-for="(job, index) in jobs" :key="index" cols="4">
      <v-card>
        <v-card-item>
          <v-card-title class="d-flex flex-row align-center">
            <div class="flex-grow-1 text-truncate" style="font-size: 1rem;">{{job.Occupation}}</div>
            <v-spacer></v-spacer>
            <v-btn x-small flat icon @click="toggleHeart(job.ID)" style="padding: 2px;">
              <v-icon :color="isJobSaved(job.ID) ? 'red' : 'grey'" size="20">{{ heartIcon(job.ID) }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="d-flex align-center">
            <v-icon color="green" icon="mdi-cash" size="small" style="margin-right: 5px;"></v-icon>
            <span>${{ (job.Income_Min / 1000).toFixed(0) }}k -  ${{ (job.Income_Max / 1000).toFixed(0) }}k</span>
            
          </v-card-subtitle>
        </v-card-item>
    
        <v-card-text>
          <v-row align="center" class="mx-0 pt-1">
            <span>Regulated: {{ job.Regulated}}</span>
            <v-icon :color="getColor(job.Regulated)" :icon="getIcon(job.Regulated)" size="small" style="margin-left: 5px;"></v-icon>
          </v-row>
          <v-row 
            
            align="center"
            class="mx-0 pt-1"
          >
          <span>Type: <strong>{{ job.Commitment }}</strong></span>
          </v-row>
    
          <v-row justify="space-around" style="padding: 15px 0;">
            <v-chip :color="getColor(job.Prospects.replace(' ', '_'))" style="height: auto; margin-right: 10px;">
              <v-icon :color="getColor(job.Prospects.replace(' ', '_'))" icon="mdi-finance" size="small" style="margin-right: 5px;"></v-icon>
              {{ job.Prospects }}
            </v-chip>
            <v-chip style="height: auto; margin-right: 10px;">
              <v-icon color="green" icon="mdi-map-marker" size="x-small" style="margin-right: 5px;"></v-icon>
              {{ job.Virtual ? job.Virtual.split(',')[0] : 'N/A' }}
            </v-chip>
            <v-chip style="height: auto; margin-right: 10px;">
              <v-icon color="green" icon="mdi-tag" size="x-small" style="margin-right: 5px;"></v-icon>
              {{ job.Count.length }} {{ job.Count.length > 1 ? '+' : '' }}
            </v-chip>
          </v-row>
        </v-card-text>
        <v-divider class="mx-4 mb-1"></v-divider>
    
    
        <v-card-actions>
          <v-btn
            color="primary"
            text="Details"
            block
            border
            :to="{ name: 'JobDetails', params: { jobId: job.ID } }"
          ></v-btn>
        </v-card-actions>
          </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { useUserStore}  from '../store/store';
import router from '../router/router';
import instance from '../store/axios';
import { iconMap } from './UserResults.vue';


export default {
  setup() {
    const jobs = ref([]);

    const isLoading = ref(false); // local loading state
    const userStore = useUserStore();
    const cf_saved = ref(sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : []);

    const getColor = (category) => iconMap[category]?.color || 'grey';
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    const isJobSaved = (jobId) => {
      return cf_saved.value.includes(jobId);
    };

    const heartIcon = (jobId) => isJobSaved(jobId) ? 'mdi-heart' : 'mdi-heart-outline';

    const toggleHeart = async (jobId) => {
      if (isJobSaved(jobId)) {
        const index = cf_saved.value.indexOf(jobId);
        cf_saved.value.splice(index, 1);
        // Update the UI first
        await nextTick();
        // Then make the DELETE axios call
        instance.delete('profile/saved', {
          data: { jobId },
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(() => {
              console.log('Job deleted successfully');
            })
            .catch(error => {
              console.error('Failed to delete job:', error);
              console.error('Error details:', error.response);

              // If the DELETE request fails, add the jobId back to cf_saved
              cf_saved.value.push(jobId);
            });
      } else {
        cf_saved.value.push(jobId);
        // Update the UI first
        await nextTick();
        // Then make the PUT axios call
        instance.put('profile/saved', {
          data: { jobId },
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(() => {
              console.log('Job saved successfully');
            })
            .catch(error => {
              console.error('Failed to save job:', error);
              console.error('Error details:', error.response);
              // If the PUT request fails, remove the jobId from cf_saved
              const index = cf_saved.value.indexOf(jobId);
              cf_saved.value.splice(index, 1);
            });
      }
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved.value));
    };
    
    const clearAndGoBack = () => {
      userStore.clearResults(); // Clear searchResults
      router.go(-1); // Go back
    };

    onMounted(async () => {
      try {
        
        isLoading.value = true;
        console.log("store data", userStore.cf_saved_jobs); // Log searchResults

        const cf_saved = sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : [];

        if (userStore.cf_saved_jobs && userStore.cf_saved_jobs.length === cf_saved.length) {
          jobs.value = userStore.cf_saved_jobs;
        } 
        
        else {

          const response = await instance.get('profile/saved', {
            params: { 'cf_saved': cf_saved },
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log(window.location.href); // Log the current URL

          console.log('Response:', JSON.stringify(response, null, 2)); // Log the response

          jobs.value = response.data.map(job => ({ // Map the response data otherwise it won't display properly lol
            Occupation: job.occupation,
            Description: job.description,
            Prospects: job.job_prospects,
            Regulated: job.regulated,
            Income_Min: job.income_min,
            Income_Max: job.income_max,
            ID: job.id,
            Trend: job.job_growth,
            Virtual: job.virtual,
            Count: job.Families,
            Commitment: job.commitment,
          }));

          userStore.setCfSavedJobs(jobs.value); // Save the results to the store
        }
          isLoading.value = false;
        } 
      catch (error) {
        console.error('Failed to fetch jobs:', error);
        isLoading.value = false;
        }
      });

    return { 
      jobs,
      heartIcon,
      isLoading,
      isJobSaved,
      toggleHeart,
      clearAndGoBack,
      getColor,
      getIcon
    };
  }
}
</script>

