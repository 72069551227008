<template>
  <div class="fill-height">
    <v-toolbar>
      <v-btn icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>Back</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2">
      <v-row>
        <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary">
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <div v-else>
          
          <v-container fluid class="px-12">
            <v-row>
              <v-col cols="4">
                <v-card class="mb-6">
                  <v-card-title style="margin-left: 20px;">Income Details</v-card-title>
                  <div style="padding: 0 30px;">
                    <v-divider></v-divider>
                  </div>
                  <v-card-text class="pa-0">
                    <v-list class="py-2">
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="green" class="mr-2">mdi-currency-usd</v-icon>
                          Salary
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap pl-8">${{ (job.income_min / 1000).toFixed(0) }}k -  ${{ (job.income_max / 1000).toFixed(0) }}k</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-clock-outline</v-icon>
                          Hourly
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap pl-8">{{ job.hourly_min }}/hr -  {{ job.hourly_max }}/hr</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
                <v-card class="mb-6">
                  <v-card-title style="margin-left: 20px;">Regulatory Status</v-card-title>
                  <div style="padding: 0 30px;">
                    <v-divider></v-divider>
                  </div>
                  <v-card-text class="pa-0">
                    <v-list class="py-2">
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon :color="getColor(job.regulated)" :icon="getIcon(job.regulated)" class="mr-2"></v-icon>
                          Is this job regulated?
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap pl-8" style="font-size: 1rem;">
                          {{ job.regulated === 'No' ? 'This job is not regulated' : job.regulated === 'Both' ? 'This job can be regulated' : job.regulated }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <template v-if="job.regulated !== 'No'">
                        <v-list-item>
                          <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                            <v-icon left color="amber darken-4" class="mr-2">mdi-certificate</v-icon>
                            Program Name
                          </v-list-item-title>
                          <v-list-item-subtitle class="d-flex flex-wrap pl-8">
                            <ul style="list-style-type: disc; padding-left: 20px;">
                              <li v-for="(item, index) in (job.program_name ? job.program_name.split(/[,;]/) : [])" :key="index" class="text-wrap">
                                {{ item.trim() }}
                              </li>
                            </ul>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                            <v-icon left color="primary" class="mr-2">mdi-town-hall</v-icon>
                            School Location
                          </v-list-item-title>
                          <v-list-item-subtitle class="d-flex flex-wrap pl-8">{{ job.school_location }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                            <v-icon left color="green" class="mr-2">mdi-cash</v-icon>
                            Average Cost
                          </v-list-item-title>
                          <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8">${{ job.average_cost }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                            <v-icon left color="primary" class="mr-2">mdi-book-open-variant</v-icon>
                            Study Time
                          </v-list-item-title>
                          <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8">{{ job.minimum_study_time }} months</v-list-item-subtitle>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
                <v-card class="mb-6">
                  <v-card-title style="margin-left: 20px;">Additional Info</v-card-title>
                  <div style="padding: 0 30px;">
                    <v-divider></v-divider>
                  </div>
                  <v-card-text class="pa-0">
                    <v-list class="py-2">
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-briefcase-account</v-icon>
                          Job Prospects
                        </v-list-item-title>
                        <v-chip :color="job.job_prospects ? getColor(job.job_prospects.replace(' ', '_')) : ''" style="height: auto; margin-left: 30px;">
                          {{ job.job_prospects }}
                        </v-chip>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="amber" class="mr-2">mdi-trophy</v-icon>
                          Competition
                        </v-list-item-title>
                        <v-chip :color="job.job_prospects ? getColor(job.job_prospects.replace(' ', '_')) : ''" style="height: auto; margin-left: 30px;">
                          {{ job.competition }}
                        </v-chip>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-finance</v-icon>
                          Trend
                        </v-list-item-title>
                        <v-chip :color="job.job_growth ? getColor(job.job_growth.replace(' ', '_')) : ''" style="height: auto; margin-left: 30px;">
                          {{ job.job_growth }}
                        </v-chip>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-briefcase-clock</v-icon>
                          Type
                        </v-list-item-title>
                        <v-row no-gutters wrap style="margin-left: 30px;">
                          <v-chip v-for="(item, index) in (job.commitment ? job.commitment.split(/[,;]/) : [])" :key="index" style="height: auto; margin-right: 5px;">
                            {{ item.trim() }}
                          </v-chip>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-map-marker</v-icon>
                          Location
                        </v-list-item-title>
                        <v-row no-gutters wrap style="margin-left: 30px;">
                          <v-chip v-for="(item, index) in (job.virtual ? job.virtual.split(/[,;]/) : [])" :key="index" style="height: auto; margin-right: 5px;">
                            {{ item.trim() }}
                          </v-chip>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
                
              </v-col>
              <v-col cols="8">
                <v-card class="mb-6">
                  <v-card-title style="margin-left: 20px;">{{ job.occupation }}</v-card-title>
                  <div style="padding: 0 30px;">
                    <v-divider></v-divider>
                  </div>
                  <v-card-subtitle class="d-flex align-center" style="margin-top: 20px; margin-bottom: 5px; padding-left: 30px;">
                    Job Families:
                    <v-chip v-for="(item, index) in (job.Families || [])" :key="index" 
                            style="height: auto; margin-right: 5px;" 
                            :style="{ 'margin-left': index === 0 ? '5px' : '0' }">
                      {{ item.replace(/_/g, ' ').toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase()) }}
                    </v-chip>
                  </v-card-subtitle>
                    <v-list>  
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em; padding-left: 10px;">
                          <v-icon left color="primary" class="mr-2">mdi-briefcase</v-icon>
                          Description
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap text-wrap" style="font-size: 1rem; padding-left: 45px; line-height: 1.3;">
                          {{ job.description }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-check-circle</v-icon>
                          Required Skills
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8" style="font-size: 1rem;">
                          <v-chip v-for="(item, index) in (job.job_requirements_skills ? job.job_requirements_skills.split(/[,;]/) : [])" :key="index" class="mr-2 mb-2">
                            {{ item.trim() }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="amber" class="mr-2">mdi-check-decagram</v-icon>
                          Preferred Skills
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8" style="font-size: 1rem;">
                          <ul style="list-style-type: disc; padding-left: 20px;">
                            <li v-for="(item, index) in (job.job_requirements_preferred ? job.job_requirements_preferred.split(/[,;]/) : [])" :key="index" class="text-wrap">
                              {{ item.trim() }}
                            </li>
                          </ul>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                    <v-divider class="mx-4 mb-1"></v-divider>
                    <v-card-actions>
                      <v-row no-gutters dense>
                        <v-col cols="6" class="d-flex justify-center">
                          <v-btn
                            color="primary"
                            text="Example Job Posting"
                            block
                            border
                            class="grow rounded-0"
                          ></v-btn>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center">
                          <v-btn
                            color="secondary"
                            text="Save Button"
                            block
                            border
                            class="grow rounded-0"
                          ></v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                </v-card>
                <v-card class="mb-6">
                  <v-card-title class="text-center">Career Requirements</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-account-school</v-icon>
                          Education
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8" style="font-size: 1rem;">
                          <v-chip v-for="(item, index) in (job.job_requirements_concrete ? job.job_requirements_concrete.split(/[,;]/) : [])" :key="index" class="mr-2 mb-2">
                            {{ item.trim() }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="font-weight: bold; font-size: 1em; padding-bottom: 0.5em;">
                          <v-icon left color="primary" class="mr-2">mdi-school</v-icon>
                          Common Programs
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap text-wrap pl-8" style="font-size: 1rem;">
                          <v-chip v-for="(item, index) in (job.common_programs ? job.common_programs.split(/[,;]/) : [])" :key="index" class="mr-2 mb-2">
                            {{ item.trim() }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import instance from '../store/axios';
import { iconMap } from './UserResults.vue';

export default {
  props: ['jobId'],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false); // local loading state
    const jobId = ref(props.jobId ? props.jobId : 2302004);
    const tab = ref(null); // Define tab
    const job = ref({});

    const getColor = (category) => iconMap[category]?.color || 'grey';
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    onMounted(async () => {
      jobId.value = route.params.jobId;
      try {
        isLoading.value = true;

        const response = await instance.get(`search/results/${jobId.value}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log('Response:', JSON.stringify(response, null, 2)); // Log the response

        // Handle a single job object
        if (response.data && response.data.length > 0) {
          const jobData = response.data[0]; // Access the first item in the array
          job.value = { ...jobData };     // Thanks ChatGPT
        }
        isLoading.value = false;
      } 
      catch (error) {
        console.error('Failed to fetch job:', error);
        isLoading.value = false;
      }
    });

    const goBack = () => {
      router.go(-1);
    };

    return { 
      goBack,
      isLoading,
      tab,
      job,
      getColor,
      getIcon
    };
  },
};
</script>