<template>
  <v-app>
    <v-app-bar :elevation="2" color="primary">
      <v-container class="d-flex align-center" fluid>
        <v-row no-gutters class="flex-grow-1">
          <v-col cols="auto" class="d-flex align-center">
            <v-btn text color="white" :to="{ name: 'HomeSplash' }">
              <v-toolbar-title>CareerFit MVP</v-toolbar-title>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-tabs v-model="activeTab" align-with-title>
              <v-tab :ripple="false" :to="{ name: 'UserFormWizard' }">Try it for free</v-tab>
              <v-tab :ripple="false" :to="{ name: 'HowItWorks' }">How it works</v-tab>
              <v-tab :ripple="false" :to="{ name: 'AboutUs' }">About Us</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="auto" class="d-flex align-center justify-end">
            <v-btn text color="white" :to="{ name: 'UserLogin' }">Login</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <router-view />
  </v-app>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'DefaultLayout',
  setup() {
    const router = useRouter();
    const activeTab = ref(null);

    watch(() => router.currentRoute.value, (to) => {
      if (to.path === '/') {
        activeTab.value = null;
      }
    }, { immediate: true });

    return {
      activeTab,
    };
  },
};
</script>

<!-- Your styles here -->

<style scoped>
.v-toolbar-title {
  cursor: pointer;
}

h1 {
  font-weight: bold;
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 20px;
}

.custom-container {
  padding-left: 100px; /* Decrease this value to move the content to the left */
  padding-top: 150px;
}

.custom-col {
  max-width: 100%;
}

.subtitle-1 {
  color: #6c757d;
}
</style>