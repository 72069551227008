<template>
  <div class="fill-height">
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container fluid >
        <v-row no-gutters>
          <v-col cols="8">
            <v-container >
              <v-card :style="{ height: '406px' }">
                <v-card-title class="text-center">{{ mentorId.name }}</v-card-title>
                <v-card-text>
                  <br>
                  Description: {{ mentorId.description }}
                  <br>
                  <br>
                  <v-divider></v-divider>
                  <br>
                  Outcome: {{ mentorId.outcomes }}
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>
          <v-col cols="4">
            <v-container >
              <v-card>
                <v-card-title class="text-center">Course Details</v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item prepend-icon="mdi-clock-time-four-outline">
                      <v-list-item-title >{{ mentorId.duration }} minutes</v-list-item-title>
                    </v-list-item>
                    <v-list-item prepend-icon="mdi-television-play">
                      <v-list-item-title >Video Course</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                  <div class="d-flex justify-center">
                    <v-chip
                      :color="enrollmentStatus === 'Enrolled' ? 'green' : enrollmentStatus === 'Pending Enroll' ? 'orange' : 'red'"
                      outlined
                      dark
                    >
                      {{ enrollmentStatus }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
            <v-container>
              <v-card>
                <v-card-title class="text-center">Course Delivery</v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item prepend-icon="mdi-account-school">
                      <v-list-item-title >Mihai Csaki</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                  <div class="d-flex justify-center">
                    <v-dialog v-model="dialog" max-width="600">
                      <template v-slot:activator="{ props: activatorProps }">
                        <v-btn color="primary" v-bind="activatorProps" :disabled="enrollmentStatus !== 'Not Enrolled'">Enroll</v-btn>
                      </template>
                      <v-card>
                        <v-toolbar color="primary" dark flat class="rounded-0">
                          <v-toolbar-title class="headline text-center">
                            Enroll: {{ mentorId.name }}
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="6">
                                <v-menu v-model="isMenuOpen" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                    <v-text-field
                                      label="Selected date"
                                      :model-value="formattedDate"
                                      readonly
                                      v-bind="props"
                                      @mouseover="icon = 'mdi-cursor-default-click'"
                                      :append-inner-icon="isMenuOpen ? 'mdi-menu-up' : 'mdi-menu-down'"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker value-format="yyyy-MM-dd" color="primary" v-model="selectedDate"></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="6">
                                <v-select
                                  :items="times"
                                  label="Select a time"
                                  v-model="selectedTime"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn @click="dialog = false">Close</v-btn>
                          <v-btn color="primary" @click="dialog = false; confirmDialog = true">Enroll</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="confirmDialog" max-width="400">
                      <v-card>
                        <v-toolbar color="primary" dark flat class="rounded-0">
                          <v-toolbar-title class="headline text-center">
                            Confirm Enrollment
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-row class="py-2">
                                  <v-col cols="7">
                                    <span>Confirm Date:</span>
                                  </v-col>
                                  <v-col cols="5">
                                    <strong>{{ formattedDate }}</strong>
                                  </v-col>
                                </v-row>
                                <v-row class="py-1">
                                  <v-divider></v-divider>
                                </v-row>
                                <v-row class="py-1">
                                  <v-col cols="7">
                                    <span>Confirm Time:</span>
                                  </v-col>
                                  <v-col cols="5">
                                    <strong>{{ selectedTime }}</strong>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn @click="confirmDialog = false">Cancel</v-btn>
                          <v-btn color="primary" @click="confirmEnrollment" :loading="confirmloading">Confirm</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-container >
              <v-card>
                <v-card-title class="text-center">Intro Video (Demo Slot)</v-card-title>
                <v-card-text>
                  <v-img :src="require('../assets/vidload.png')"></v-img>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '../store/store';
import instance from '../store/axios';




export default {
  props: ['sessionId'],
  setup(props) {
    const route = useRoute();
    const isLoading = ref(false); // local loading state
    const confirmloading = ref(false);
    const dialog =  ref(false);
    const mentorId = ref({}); // Define mentorId as a ref to hold the response data
    const sessionIdentifier = ref(props.sessionId || route.params.sessionId); // Define sessionIdentifier as a ref
    const userStore = useUserStore();
    const menu = ref(false);
    const isMenuOpen = ref(false)
    const selectedDate = ref()
    const confirmDialog = ref(false);
    const selectedTime = ref('');
    const formattedDate = computed(() => {
      return selectedDate.value ? selectedDate.value.toISOString().slice(0,10) : '';
    });
    const times = ref([
      '08:00', '09:00', '10:00', '11:00',
      '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'
    ]);

    const confirmEnrollment = async () => {
      confirmloading.value = true;
      try {
        await instance.put('coaching/enroll', {
          date: formattedDate.value,
          time: selectedTime.value,
          sessionId: sessionIdentifier.value,
          sessionName: mentorId.value.name
          // other data...
        });
        if (userStore.cf_coaching[sessionIdentifier.value]) {
          // Update the status of an existing session
          userStore.cf_coaching[sessionIdentifier.value][2] = "Pending Enroll";
        } else {
          // Create a new session
          userStore.cf_coaching[sessionIdentifier.value] = [selectedTime.value, formattedDate.value, "Pending Enroll", mentorId.value.name];
        }
        
        

        dialog.value = false;
        confirmDialog.value = false;
        isMenuOpen.value = false;
      } catch (error) {
        console.error('Failed to enroll:', error);
      } finally {
        confirmloading.value = false;
      }
    };

    const fetchEnrolled = async () => {
      // If cf_coaching is already present in userStore, return early
      if (userStore.cf_coaching && Object.keys(userStore.cf_coaching).length > 0) {
        console.log(`cf_coaching already exists in userStore`);
        console.log(userStore.cf_coaching);
        return;
      }

      try {
        console.log(`Fetching enrolled data`);
        const response = await instance.get('coaching/enroll', {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log(`response data fetched`);
        console.log(response.data);
        userStore.setEnrolledCoach(response.data.cf_coaching);
      } catch (error) {
        console.error(`Error fetching enrolled data:`, error);
      }
    };
    const fetchData = async () => {
      try {

        const response = await instance.get(`coaching/${sessionIdentifier.value}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log('Response:', JSON.stringify(response, null, 2)); // Log the response

        // Handle an array of session objects
        if (response.data && response.data.length > 0) {
          const session = response.data[0]; // Take the first session object from the array

          mentorId.value = {
            name: session.name,
            description: session.description,
            outcomes: session.outcomes,
            structure: session.structure,
            duration: session.duration
          };
        }
        console.log('Data:', mentorId.value);
      } 
      catch (error) {
        console.error('Failed to fetch session:', error);
      }
    };

    const enrollmentStatus = computed(() => {
      const enrolledCoaching = userStore.cf_coaching;
      return enrolledCoaching && enrolledCoaching[sessionIdentifier.value] 
        ? enrolledCoaching[sessionIdentifier.value][2] 
        : 'Not Enrolled';
    });

    onMounted(async () => {
      isLoading.value = true;
      try {
        await Promise.all([fetchData(), fetchEnrolled()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      isLoading.value = false;
    });

    watch(() => route.params.sessionId, async () => {
      isLoading.value = true;
      sessionIdentifier.value = route.params.sessionId;
      try {
        await Promise.all([fetchData(), fetchEnrolled()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      isLoading.value = false;
    });

    return { 
      isLoading,
      isMenuOpen,
      menu,
      times,
      selectedDate,
      formattedDate,
      mentorId,
      sessionIdentifier,
      enrollmentStatus,
      dialog,
      confirmDialog, // add this 
      confirmloading,
      selectedTime, // add this line
      confirmEnrollment // add this line
    };
  },
};
</script>