// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

export default createVuetify({
  directives,
  components,
  theme: {
    defaultTheme: 'myTheme',
    themes: {
      myTheme: {
        primary: '#ffffff'
      },
    },
  },
})
