<template>
  <v-main style="background-color: #edf8ff;">
    <v-row>
      <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <div class="custom-container d-flex flex-column align-center justify-center">
          <h1 class="display-1 text-center mb-10">Who are we? We are Internationally Educated Health Professionals (IHEPS) too!</h1>
          <v-btn href="https://www.rimax.ca" target="_blank" color="primary" class="mb-10">
            Go To Rimax.ca
          </v-btn>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Mihai Csaki - Founder & An Experienced Mentor and Guide
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/mihaicsaki/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Career Coach & Specialist | Alternative career paths | IMGs | Clinical Research | Pharmaceutical Space | Digital health | Medical Affairs</h5>
              <p class="bio-description">
                International Medical Graduate ( EU license restricted), held executive positions in Marketing, Sales, Clinical research, Headhunting and Occupational Health services, Scientific Affairs. Experienced in Career coaching, Adult Learning, Pharmaceuticals, Medical Devices, Pharmacy, Entrepreneurship, with a deep interest in Digital Health.
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Mihai.jpg"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Abbas.jpg"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Abbas Zavar - A Mentor and Digital Health Specialist
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/abbaszavar/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Career Coach & Specialist | Health Informatics Coach | IMGs | Public Health | Digital health</h5>
              <p class="bio-description">
                An international medical doctor who also held Master of Public Health and Master of Health Informatics, with solid expertise in adult education, coaching and mentoring. Outstanding knowledge and experience in the Canadian digital health domain: clinical decision support system, clinical business intelligence and clinical data sciences.
              </p>
            </v-col>
          </v-row>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Cindy - A Mentor, Career Coach, Educator and Researcher
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/cindy-sinclair-11a92520/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Alternative professional and academic careers paths | Immigrant Medical Doctors | IEHPs | Workplace Integration | Social Science Research</h5>
              <p class="bio-description">
                U of Toronto affiliated PhD educator, researcher and former staff with 10+ years experience in coaching, guiding and mentoring students and newcomer immigrants in achieving their desired job and education goals. A warm and empathic mentor with proven track record on achieving higher goals.
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Cindy.jpeg"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Pauline.jpeg"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Pauline - A Career Coach & Specialist
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/abbaszavar/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Career Coach & Specialist | Alternative career paths | IMDs & IEHPs | Clinical Research | Medical Administration | Hospital and Healthcare</h5>
              <p class="bio-description">
                International Medical Doctor (IMD) currently working in the field of clinical research at a leading academic institute in Toronto. Extensive knowledge in Canadian healthcare structure, context & culture, clinical research, and medical administration. Years of experience in career guidance and mentoring of internationally educated healthcare professionals (IEHPs).
              </p>
            </v-col>
          </v-row>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Ioana Modroiu - A Peer and Guide to IEHPs
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/ioanamodroiu/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Career Coach & Specialist | Alternative career paths | IMGs | Clinical Research | Hospital & Healthcare</h5>
              <p class="bio-description">
                An International Medical Doctor currently working as a Senior Training Manager for a Pharmaceutical company in GTA.
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Ioana.jpg"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-container class="my-5" style="border-top: 1px solid lightgray; height: 0;"></v-container>
          <v-row align="center" class="mb-10">
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
              <div class="image-container">
                <v-img
                  src="../assets/pictures/Swetha.png"
                  alt="Team member photo"
                  class="rounded-img"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <h2 class="name-header">
                Swetha - A Peer and Guide to IEHPs
                <v-btn 
                  icon 
                  href="https://www.linkedin.com/in/swetha-rc/" 
                  target="_blank" 
                  text 
                  style="background-color: transparent; box-shadow: none; border: none; padding: 0;">
                  <v-icon color="primary" size="35">mdi-linkedin</v-icon>
                </v-btn>
              </h2>
              <h5 class="subheading">Career Coach | Alternative career paths | IMGs | Digital health</h5>
              <p class="bio-description">
                An Internationally Educated Family Physician with recent work experience as a career coach and mentor. Interested in exchanging knowledge on career search options in Canadian healthcare.
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'DefaultLayout',
  setup() {
    const router = useRouter();
    const activeTab = ref(null);
    const resetActiveTab = () => {
      activeTab.value = null;
    };
    const goToLogin = () => {
      router.push({ name: 'UserLogin' });
    };
    const goToFormWizard = () => {
      router.push({ name: 'UserFormWizard' });
    };

    return {
      activeTab,
      goToLogin,
      goToFormWizard,
      resetActiveTab
    };
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(to) {
        if (to === '/') {
          this.resetActiveTab();
        }
      },
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-weight: bold;
}


.custom-container {
  padding-left: 100px;
  padding-top: 150px;
  padding-right: 100px;
  text-align: center;
}

.name-header {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.title-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.image-container {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
}

.rounded-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bio-description {
  font-size: 16px;
  color: #6c757d;
}

.subheading {
  font-size: 16px;
  color: rgb(125, 125, 125);
  margin-bottom: 30px;
}

.mb-10 {
  margin-bottom: 40px;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}
.v-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>