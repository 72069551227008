<template>
  <!-- Loading spinner -->
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          size="70"
          width="7"
          color="primary">
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <!-- Navigation Drawer -->
  <v-navigation-drawer v-model="drawer" app permanent clipped width="200">
  <!-- Title for the Filter Results -->
  <div class="filter-title">
    Filter Results
  </div>
    <v-expansion-panels>
      <!-- Job Families Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Job Families</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="family in jobFamilies"
            :key="family"
            :label="formatFamilyName(family)"
            :value="family"
            v-model="selectedJobFamilies"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
  
      <!-- Regulated Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Regulated</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="option in filterRegulated"
            :key="option"
            :label="option"
            :value="option"
            v-model="selectedRegulated"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
  
      <!-- Prospects Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Prospects</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="option in filterCompetition"
            :key="option"
            :label="option"
            :value="option"
            v-model="selectedCompetition"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
  
      <!-- Growth Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Growth</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="option in filterJobGrowth"
            :key="option"
            :label="option"
            :value="option"
            v-model="selectedJobGrowth"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
  
      <!-- Virtual Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Virtual</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="option in filterVirtual"
            :key="option"
            :label="option"
            :value="option"
            v-model="selectedVirtual"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
  
      <!-- Commitment Panel -->
      <v-expansion-panel>
        <v-expansion-panel-title>Commitment</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-checkbox
            v-for="option in filterCommitment"
            :key="option"
            :label="option"
            :value="option"
            v-model="selectedCommitment"
            density="compact"
            hide-details="auto"
            class="small-checkbox"
          ></v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-navigation-drawer>

  <!-- Toolbar with Back and Filter buttons -->
  <v-toolbar>
    <!-- Wrapper to group the Back button and Filter button together -->
    <div class="d-flex align-center">
      <!-- Back button -->
      <v-btn icon @click="clearAndGoBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>Back</v-toolbar-title>
  
      <!-- Filter button -->
      <v-btn icon @click="toggleDrawer">
        <v-icon color="grey darken-4">mdi-filter</v-icon>
      </v-btn>Filters
    </div>
  
    <!-- Spacer to push the text to the right -->
    <v-spacer></v-spacer>
  
    <!-- Text indicating the number of careers found -->
    <v-toolbar-items class="d-flex align-center justify-center subtitle-1 pr-4">
      Found {{ jobs.length }} careers
    </v-toolbar-items>
  </v-toolbar>
  
  
  <!-- Cards -->
  <v-row class="pa-5 justify-center">
    <v-col v-for="(job, index) in filteredJobs" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
      <v-card>
    <v-card-item>
      <v-card-title class="d-flex flex-row align-center">
        <div class="flex-grow-1 text-truncate" style="font-size: 1rem;">{{job.Occupation}}</div>
        <v-spacer></v-spacer>
        <v-btn x-small flat icon @click="toggleHeart(job.ID)" style="padding: 2px;">
          <v-icon :color="isJobSaved(job.ID) ? 'red' : 'grey'" size="20">{{ heartIcon(job.ID) }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="d-flex align-center">
        <v-icon color="green" icon="mdi-cash" size="small" style="margin-right: 5px;"></v-icon>
        <span>${{ (job.Income_Min / 1000).toFixed(0) }}k -  ${{ (job.Income_Max / 1000).toFixed(0) }}k</span>
        
      </v-card-subtitle>
    </v-card-item>

    <v-card-text>
      <v-row align="center" class="mx-0 pt-1">
        <span>Regulated: {{ job.Regulated}}</span>
        <v-icon :color="getColor(job.Regulated)" :icon="getIcon(job.Regulated)" size="small" style="margin-left: 5px;"></v-icon>
      </v-row>
      <v-row 
        
        align="center"
        class="mx-0 pt-1"
      >
      <span>Type: <strong>{{ job.Commitment }}</strong></span>
      </v-row>

      <v-row justify="space-around" style="padding: 15px 0;">
        <v-chip :color="getColor(job.Prospects.replace(' ', '_'))" style="height: auto; margin-right: 10px;">
          <v-icon :color="getColor(job.Prospects.replace(' ', '_'))" icon="mdi-finance" size="small" style="margin-right: 5px;"></v-icon>
          {{ job.Prospects }}
        </v-chip>
        <v-chip style="height: auto; margin-right: 10px;">
          <v-icon color="green" icon="mdi-map-marker" size="x-small" style="margin-right: 5px;"></v-icon>
          {{ job.Virtual ? job.Virtual.split(',')[0] : 'N/A' }}
        </v-chip>
        <v-chip style="height: auto; margin-right: 10px;">
          <v-icon color="green" icon="mdi-tag" size="x-small" style="margin-right: 5px;"></v-icon>
          {{ job.Count.length }} {{ job.Count.length > 1 ? '+' : '' }}
        </v-chip>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4 mb-1"></v-divider>


    <v-card-actions>
      <v-btn
        color="primary"
        text="Details"
        block
        border
        :to="{ name: 'JobDetails', params: { jobId: job.ID } }"
      ></v-btn>
    </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, onMounted, nextTick, computed} from 'vue';
import { useUserStore}  from '../store/store';
import router from '../router/router';
import { filterCommitment, filterRegulated, filterCompetition, filterJobGrowth, filterVirtual } from '@/config/forms';
import instance from '../store/axios';

export const iconMap = {
      Yes: { color: 'orange', icon: 'mdi-alert-circle' },
      Both: { color: 'blue', icon: 'mdi-alert-box' },
      No: { color: 'green', icon: 'mdi-check-circle' },
      Very_Good: { color: 'green', icon: 'mdi-finance' },
      Good: { color: 'green', icon: 'mdi-finance' },
      Fair: { color: 'orange', icon: 'mdi-finance' },
      Stable: { color: 'lue', icon: 'mdi-finance' },
      Low: { color: 'green', icon: 'mdi-finance' },
      High: { color: 'lue', icon: 'mdi-finance' },
      Steady: { color: 'lue', icon: 'mdi-finance' },
      Growing: { color: 'green', icon: 'mdi-finance' },
    };

export default {
  setup() {
    const jobs = ref([]);
    const jobFamilies = ref([]);
    const selectedJobFamilies = ref([]);
    const selectedRegulated = ref([]);
    const selectedCompetition = ref([]);
    const selectedJobGrowth = ref([]);
    const selectedVirtual = ref([]);
    const selectedCommitment = ref([]);
    const isLoading = ref(false); 
    const drawer = ref(false); 
    const userStore = useUserStore();
    const cf_saved = ref(sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : []);

    const toggleDrawer = () => {
      drawer.value = !drawer.value;
    };

    const isJobSaved = (jobId) => cf_saved.value.includes(jobId);

    const heartIcon = (jobId) => isJobSaved(jobId) ? 'mdi-heart' : 'mdi-heart-outline';

    const getColor = (category) => iconMap[category]?.color || 'grey';
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    const toggleHeart = async (jobId) => {
      if (isJobSaved(jobId)) {
        const index = cf_saved.value.indexOf(jobId);
        cf_saved.value.splice(index, 1);
        await nextTick();
        instance.delete('profile/saved', {
          data: { jobId },
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(() => console.log('Job deleted successfully'))
          .catch(error => {
            console.error('Failed to delete job:', error);
            cf_saved.value.push(jobId);
          });
      } else {
        cf_saved.value.push(jobId);
        await nextTick();
        instance.put('profile/saved', {
          data: { jobId },
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(() => console.log('Job saved successfully'))
          .catch(error => {
            console.error('Failed to save job:', error);
            const index = cf_saved.value.indexOf(jobId);
            cf_saved.value.splice(index, 1);
          });
      }
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved.value));
    };

    const clearAndGoBack = () => {
      //userStore.clearResults();
      router.go(-1);
    };

    const filteredJobs = computed(() => {
      return jobs.value.filter(job => {
        const matchesFamilies = selectedJobFamilies.value.length === 0 || job.Count.some(family => selectedJobFamilies.value.includes(family));

        const matchesRegulated = selectedRegulated.value.length === 0 || selectedRegulated.value.includes(job.Regulated);

        const matchesCompetition = selectedCompetition.value.length === 0 || selectedCompetition.value.includes(job.Prospects);

        const matchesJobGrowth = selectedJobGrowth.value.length === 0 || selectedJobGrowth.value.includes(job.Trend);

        // Handle comma-separated values in job.Virtual
        const matchesVirtual = selectedVirtual.value.length === 0 || selectedVirtual.value.some(selected => job.Virtual.split(',').map(v => v.trim()).includes(selected));

        // Handle comma-separated values in job.Commitment
        const matchesCommitment = selectedCommitment.value.length === 0 || selectedCommitment.value.some(selected => job.Commitment.split(',').map(c => c.trim()).includes(selected));

        return matchesFamilies && matchesRegulated && matchesCompetition && matchesJobGrowth && matchesVirtual && matchesCommitment;
      });
    });

    onMounted(async () => {
      try {
        isLoading.value = true;

        const storedResults = sessionStorage.getItem('cf_results');
        if (storedResults) {
          jobs.value = JSON.parse(storedResults);
        } else if (userStore.searchResults.length > 0) {
          jobs.value = userStore.searchResults;
        } else {
          const cf_search = sessionStorage.getItem('cf_search') ? JSON.parse(sessionStorage.getItem('cf_search')) : null;
          const response = await instance.get('search/results', {
            params: cf_search,
            headers: {
              'Content-Type': 'application/json',
            },
          });

          console.log('Response:', JSON.stringify(response, null, 2));  // Use JSON.stringify with indentation

          jobs.value = response.data.map(job => ({
            Occupation: job.occupation,
            Description: job.description,
            Prospects: job.job_prospects,
            Regulated: job.regulated,
            Income_Min: job.income_min,
            Income_Max: job.income_max,
            ID: job.id,
            Trend: job.job_growth,
            Virtual: job.virtual,
            Count: job.Families,
            Commitment: job.commitment,
          }));

          userStore.setCfResults(jobs.value);
        }

        // Job Families Logic
        if (Array.isArray(userStore.jobFamilies) && userStore.jobFamilies.length > 0) {
          jobFamilies.value = userStore.jobFamilies;
        } else {
          const familiesSet = new Set();
          jobs.value.forEach(job => {
            job.Count.forEach(family => {
              familiesSet.add(family);
            });
          });
          jobFamilies.value = Array.from(familiesSet);
          userStore.setJobFamilies(jobFamilies.value);
        }

        isLoading.value = false;
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
        isLoading.value = false;
      }
    });

    const formatFamilyName = (family) => {
      return family.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return {
      jobs,
      filteredJobs,
      jobFamilies,
      selectedJobFamilies,
      selectedRegulated,
      selectedCompetition,
      selectedJobGrowth,
      selectedVirtual,
      selectedCommitment,
      heartIcon,
      isLoading,
      isJobSaved,
      toggleHeart,
      clearAndGoBack,
      toggleDrawer,
      drawer,
      getColor,
      getIcon,
      formatFamilyName,
      filterCommitment, // make sure these are returned
      filterRegulated,
      filterCompetition,
      filterJobGrowth,
      filterVirtual,
    };
  }
};
</script>

<style>
.small-checkbox .v-label {
  font-size: 10px; /* Smaller font size */
}
.filter-title {
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.v-toolbar {
  display: flex;
  justify-content: flex-start;
}

.v-toolbar-title {
  margin-right: 10px;
}

.ml-2 {
  margin-left: 8px; /* Adjust spacing as needed */
}
</style>