<template>
  <v-main class="d-flex justify-center align-center" style="background-color: #edf8ff; padding: 0;">
        <div class="custom-container">
          <v-row align="center" class="d-flex flex-nowrap">
            <v-col class="custom-col" cols="12" md="6">
              <h1 class="display-1">Find Your Dream Career</h1>
              <p class="subtitle-1 mb-4">
                Search a vetted database of over 600 Canadian Healthcare Careers. Understand the regulatory bodies and how to apply to these various careers. Enrol in a curated coaching program to ensure you succeed and kickstart your career in Canada!
              </p>
              <h4 class="mb-4">500+ Careers, 50+ Coaching programs</h4>
              <v-btn color="primary" class="mb-4" @click="goToFormWizard">Try CareerFit for free!</v-btn>
            </v-col>
            <v-col cols="12">
              <v-img :src="require('../assets/pictures/homepage2.png')" alt="Career image" class="career-image"></v-img>
            </v-col>
          </v-row>

          <!-- Icons and descriptions -->
          <v-row align="center" class="mt-20">
            <v-col cols="12" md="4" class="text-center d-flex flex-column align-center content-box">
              <v-icon size="100" color="primary">mdi-list-status</v-icon>
              <h3 class="icon-header">Learn What Fits You</h3>
              <p class="icon-description">Filter through 16 Job Families to learn what options are available within the healthcare space in Canada, tailored to you!</p>
            </v-col>
            <v-col cols="12" md="4" class="text-center d-flex flex-column align-center content-box">
              <v-icon size="100" color="green">mdi-check-circle</v-icon>
              <h3 class="icon-header">Learn Canadian Regulatory Bodies</h3>
              <p class="icon-description">Regulated careers made easy! CareerFit guides you through everything you need to understand what regulatory bodies expect from you!</p>
            </v-col>
            <v-col cols="12" md="4" class="text-center d-flex flex-column align-center content-box">
              <v-icon size="100" color="primary">mdi-briefcase-check</v-icon>
              <h3 class="icon-header">Prep and Land your Career</h3>
              <p class="icon-description">CareerFit brings access to over a dozen individual and group based coaching sessions to ensure you secure the career you want!</p>
            </v-col>
          </v-row>
        </div>
  </v-main>
</template>



<script>
export default {
  name: 'DefaultLayout',
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'UserLogin' });
    },
    goToFormWizard() {
      this.$router.push({ name: 'UserFormWizard' });
    },
    resetActiveTab() {
      this.$nextTick(() => {
        this.activeTab = null;
      });
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(to) {
        if (to === '/') {
          this.resetActiveTab();
        }
      },
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: bold;
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 20px;
}

.custom-container {
  padding-left: 80px; /* Decrease this value to move the content to the left */
  padding-right: 80px;
  padding-top: 150px;
  margin: 0 auto;
}

.custom-col {
  max-width: 100%;
}

.subtitle-1 {
  color: #6c757d;
}

v-img {
  max-width: 100%;
  height: auto;
}

.mt-20 {
  margin-top: 100px; /* Increase this value to add more spacing from the top */
}

.icon-header {
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.icon-description {
  margin-top: 8px;
  font-size: 16px;
  color: #6c757d;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.content-box {
  min-height: 300px; /* Ensure all columns have the same minimum height */
}

.career-image {
  width: 100%;
  max-width: 480px;
  margin-top: -120px;
  margin-left: 150px;
}

@media (max-width: 1904px) {
  .career-image {
    max-width: 480px;
    margin-top: -120px;
    margin-left: 80px;
  }
}

@media (max-width: 1264px) {
  .career-image {
    max-width: 380px;
    margin-top: -112px;
    margin-left: 50px;
  }
}
</style>