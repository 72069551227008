// store.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    userData: null,
    firstName: null,
    lastName: null,
    cf_search: {},
    cf_saved: {},
    cf_saved_jobs: {},
    searchResults: [],
    jobFamilies: [], // Initialize as an empty array
    cf_coaching: {},
  }),
  actions: {
    setFirstName(firstName) {
      this.firstName = firstName;
    },
    setLastName(lastName) {
      this.lastName = lastName;
    },
    setUserData(data) {
      this.userData = data;
    },
    setSessionUserData(userdata) {
      sessionStorage.setItem('userdata', JSON.stringify(userdata));
    },
    setCfSearch(cf_search) {
      sessionStorage.setItem('cf_search', JSON.stringify(cf_search));
    },
    setCfSaved(cf_saved) {
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved));
    },
    setCfResults(cf_results) {
      this.searchResults = cf_results;
    },
    setCfSavedJobs(cf_saved) {
      this.cf_saved_jobs = cf_saved;
    },
    clearResults() {
      this.searchResults = [];
      sessionStorage.removeItem('cf_results'); // Clear stored results
    },
    setEnrolledCoach(cf_coaching) {
      this.cf_coaching = cf_coaching;
    },
    setJobFamilies(families) {
      this.jobFamilies = families; // Set jobFamilies in the store
    },
  },
});

export const useFormStore = defineStore({
  id: 'forms',
  state: () => ({
    individualCoach: [],
    groupCoach: [],
  }),
  actions: {
    setIndividualCoach(individualCoach) {
      this.individualCoach = individualCoach;
    },
    setGroupCoach(groupCoach) {
      this.groupCoach = groupCoach;
    }
  },
});