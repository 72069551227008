<template>
  <v-main class="d-flex justify-center align-center" style="height: 70vh; padding: 0;">
    <v-container style="width: 100%; max-width: 1000px;">
      <v-card style="width: 100%;">
        <v-stepper
          v-model="step"
          :items="stepper"
          show-actions
        >
          <template v-slot:actions="{ next, prev }">
            <v-row no-gutters justify="space-between" class="px-3" >
              <v-col cols="2">
                <v-btn
                  color="primary"
                  @click="prev"
                  :disabled="step === 1"
                  block
                  class="mb-3">
                  Back
                </v-btn>
              </v-col>

              <v-col cols="2">
                <v-btn
                  color="primary"
                  @click="step === 4 ? submit() : next()"
                  block
                  class="mb-3">
                  {{ step === 4 ? 'Submit' : 'Next' }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <!-- Step 1 -->
          <template v-slot:[`item.1`]>
            <div>
              <h3 class="text-h6">Select Your Interested Career Families</h3>
              <br>
              <v-chip-group v-model="selectedJobFamilies" column multiple>
                <v-chip
                  v-for="(family, index) in jobFamilies"
                  :key="index"
                  filter
                  variant="outlined"
                  color="primary">
                  {{ family }}
                </v-chip>
              </v-chip-group>

              <!-- Added Select All Switch for Job Families -->
              <v-switch
                v-model="selectAllJobFamilies"
                label="Select All"
                color="primary"
                style="padding-left: 10px;"
                @change="toggleSelectAllJobFamilies"
              ></v-switch>
            </div>
          </template>

          <!-- Step 2 -->
          <template v-slot:[`item.2`]>
            <h3 class="ml-3 text-h6">Select your preferred income</h3>
            <br>
            <div class="text-h5 ml-3">
              {{ isHourly ? `CAD $${(salary / 2080).toFixed(0)}/hr` : `CAD $${salary}/yr` }}
            </div>
            <br>
            <v-slider
              class="ml-6 mr-6"
              v-model="salary"
              step="1000"
              :max="150000"
              :min="20000"
            ></v-slider>

            <v-switch
              color="primary"
              v-model="isHourly"
              label="Hourly Rate"
              class="ml-3"
            ></v-switch>
          </template>

          <!-- Step 3 -->
          <template v-slot:[`item.3`]>
            <div style="padding-bottom: 25px;">
              <h3 class="text-h6 ml-3">Select your job prospects</h3>
              <br>
              <v-chip-group
                v-model="selectedJobProspects"
                class="ml-3"
                style="padding-top: 40px;"
                multiple
                filter
                variant="outlined"
                color="primary">
                <v-chip
                  v-for="(prospect, index) in jobProspects"
                  :key="index"
                  :value="prospect"
                  color="primary"
                  text-color="white"
                >
                  {{ prospect }}
                </v-chip>
              </v-chip-group>

              <!-- Added Select All Switch for Job Prospects -->
              <v-switch
                v-model="selectAllJobProspects"
                label="Select All"
                color="primary"
                style="padding-left: 10px;"
                @change="toggleSelectAllJobProspects"
              ></v-switch>
            </div>
          </template>

          <!-- Step 4 -->
          <template v-slot:[`item.4`]>
            <div>
              <h3 class="text-h6">Select to include regulated careers</h3>

              <br>
              <br>
              <v-switch 
                v-model="includeRegulated"
                color="primary"
                label="Include Regulated Professions"
                class="ml-3"
              ></v-switch>

              <div style="height: 85px; padding-top: 10px;">
                <v-fade-transition>
                  <v-slider
                    v-if="includeRegulated"
                    v-model="timeToFirstJob"
                    class="ml-6 mr-6"
                    step="1"
                    show-ticks="always"
                    thumb-label="always"
                    :ticks="tickLabels"
                    tick-size="4"
                    :max="48"
                    :min="0"
                  ></v-slider>
                </v-fade-transition>
              </div>
            </div>
          </template>

        </v-stepper>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import router from '../router/router';
import { useUserStore } from '../store/store';
import { jobProspects, stepper, jobFamilies, preferences } from '../config/forms.js';

export default {
  setup() {
    const step = ref(1);
    const selectedJobFamilies = ref([]);
    const includeRegulated = ref(true);
    const salary = ref(20000);
    const isHourly = ref(false);
    const selectedJobProspects = ref([]);
    const timeToFirstJob = ref(0);

    // Select All switches and previous selections
    const selectAllJobFamilies = ref(false);
    const selectAllJobProspects = ref(false);
    const previousJobFamilies = ref([]);
    const previousJobProspects = ref([]);

    const userStore = useUserStore();

    const nextButtonLabel = computed(() => step.value === 4 ? 'Submit' : 'Next');
    const sessionData = sessionStorage.getItem('cf_search');

    onMounted(() => {
      // Check if user data exists in the store
      if (sessionData) {
        const data = JSON.parse(sessionData);
        selectedJobFamilies.value = data.selectedJobFamilies.map(jobFamily => jobFamilies.indexOf(jobFamily));
        selectedJobProspects.value = data.selectedJobProspects;
        salary.value = data.salary;
        isHourly.value = data.isHourly;
        includeRegulated.value = data.includeRegulated;
        timeToFirstJob.value = data.timeToFirstJob;
      }
    });

    // Toggle Select All for Job Families
    const toggleSelectAllJobFamilies = () => {
      if (selectAllJobFamilies.value) {
        previousJobFamilies.value = [...selectedJobFamilies.value];
        selectedJobFamilies.value = jobFamilies.map((_, index) => index);
      } else {
        selectedJobFamilies.value = [...previousJobFamilies.value];
      }
    };

    // Toggle Select All for Job Prospects
    const toggleSelectAllJobProspects = () => {
      if (selectAllJobProspects.value) {
        previousJobProspects.value = [...selectedJobProspects.value];
        selectedJobProspects.value = jobProspects.map((prospect) => prospect);
      } else {
        selectedJobProspects.value = [...previousJobProspects.value];
      }
    };

    const submit = async () => {
      const data = {
        selectedJobFamilies: selectedJobFamilies.value.map(index => jobFamilies[index]),
        selectedJobProspects: Object.values(selectedJobProspects.value),
        salary: salary.value,
        isHourly: isHourly.value,
        includeRegulated: includeRegulated.value,
        timeToFirstJob: timeToFirstJob.value,
      };
      userStore.setCfSearch(data);
      userStore.setCfResults([]);
      router.push({ path: '/results' });
    };

    return {
      step,
      selectedJobFamilies,
      selectedJobProspects,
      salary,
      isHourly,
      nextButtonLabel,
      submit,
      stepper,
      jobFamilies,
      jobProspects,
      preferences,
      includeRegulated,
      timeToFirstJob,
      tickLabels: {
        0: 'ASAP',
        12: '1 Year',
        24: '2 Years',
        36: '3+ years',
      },
      selectAllJobFamilies,
      toggleSelectAllJobFamilies,
      selectAllJobProspects,
      toggleSelectAllJobProspects,
    };
  },
};
</script>
