<template>
  <v-app>
    <v-app-bar app clipped-right color="primary" dark>
      <v-toolbar-title class="pl-4">CareerFit MVP</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <span>{{ firstName }} {{ lastInitial }}</span>
          <v-btn icon v-bind="props">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list class="mt-2">
          <v-list-item link v-for="(item) in profileItems" :prepend-icon="item.icon" :key="item.title" :to="item.to">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer permanent app right :width="drawerWidth">
      <v-list class="d-flex flex-column justify-space-between" style="height: 100%;">
        <div>
          <v-list-item class="fixed-top">
            <template v-slot:prepend>
              <v-btn
                class="fixed-top"
                style="right: 20px; padding-left: 20px;"
                :ripple="false"
                variant="plain"
                icon="mdi-menu"
                @click="toggleDrawerWidth">
              </v-btn>
            </template>
          </v-list-item>
          <v-divider></v-divider>
          <v-list class="py-2">
            <v-list-item
              v-for="(item, index) in navbarItems.slice(0, navbarItems.length - 1)"
              :key="index"
              :to="item.to"
              link
              exact
              :prepend-icon="item.icon">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-list-item
            :to="navbarItems[navbarItems.length - 1].to"
            link
            exact
            :prepend-icon="navbarItems[navbarItems.length - 1].icon"
            @click=feedbackOverlay()>
            <v-list-item-title>{{ navbarItems[navbarItems.length - 1].title }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div v-if="userdataLoaded">
        <router-view />
      </div>
      <v-container v-else class="d-flex flex-row align-center justify-center" style="height: 100vh;">
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="showOverlay" :width="feedbackSuccess ? '400px' : '800px'">
        <v-card style="border-radius: 16px;">
          <v-container style="max-height: 80vh; overflow-y: auto;">
            <v-row justify="end" no-gutters>
              <v-col cols="12" class="text-right" style="padding-top: 8px; padding-right: 8px;">
                <v-icon 
                  large 
                  color="grey"
                  @click="showOverlay = false" 
                  style="cursor: pointer;">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs v-model="activeTab" grow v-if="!feedbackSuccess">
                  <v-tab value="feedback">
                    <v-icon left color="primary" class="mr-2">mdi-forum</v-icon>Feedback
                  </v-tab>
                  <v-tab value="reportBug">
                    <v-icon left color="primary" class="mr-2">mdi-bug</v-icon>Report Bug
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- Show Success Icon if feedbackSuccess is true -->
                <div v-if="feedbackSuccess" class="success-message">
                  <v-icon class="success-icon" color="green">mdi-check-circle</v-icon>
                  <p class="success-text">Success</p>
                </div>
                <!-- Feedback Tab Content -->
                <div v-else>
                  <div v-if="activeTab === 'feedback'">
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-select
                            label="How happy are you with your experience?"
                            v-model="satisfactionLevel"
                            :items="['Very Satisfied', 'Satisfied', 'Not Satisfied']"
                            required
                            variant="solo-filled"
                          ></v-select>
                          <v-textarea
                            label="Your Feedback"
                            v-model="feedbackText"
                            required
                            variant="solo-filled"
                          ></v-textarea>
                          <v-btn :loading="loading" color="primary" @click="submitFeedback">Submit</v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                  <!-- Report Bug Tab Content -->
                  <div v-if="activeTab === 'reportBug'">
                    <v-card flat>
                      <v-card-text>
                        <v-form>
                          <v-text-field
                            label="Where did the bug happen?"
                            v-model="bugLocation"
                            required
                            variant="solo-filled"
                          ></v-text-field>
                          <v-textarea
                            label="Description"
                            v-model="bugDescription"
                            required
                            variant="solo-filled"
                          ></v-textarea>
                          <v-btn :loading="loading" color="primary" @click="submitBugReport">Submit</v-btn> <!-- Loading button -->
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>


<script>
import { ref, watch, onMounted } from 'vue';
import instance from '../store/axios';
import { useUserStore } from '../store/store';

export default {
  setup() {
    const userdataLoaded = ref(false);
    const userStore = useUserStore();
    const firstName = ref('');
    const lastInitial = ref('');
    const userdata = ref({
      firstName: '',
      middleName: '',
      lastName: '',
      cf_search: [],
      cf_saved: [],
    });
    const showOverlay = ref(false);
    const activeTab = ref('feedback');
    const feedbackText = ref('');
    const bugLocation = ref('');
    const satisfactionLevel = ref('');
    const bugDescription = ref('');
    const lastName = ref('');
    const email = ref('');
    const message = ref('');
    const isFeedbackActive = ref(true);
    const feedbackSuccess = ref(false); // New ref for feedback success
    const loading = ref(false); // New ref for loading state

    const profileItems = ref([
      { title: 'Profile', to: '/profile', icon: 'mdi-account-box' },
      { title: 'Settings', to: '/settings', icon: 'mdi-cog' },
      { title: 'Feedback', to: '/feedback', icon: 'mdi-message-reply-text' },
      { title: 'Sign Out', to: '/logoutSuccess', icon: 'mdi-logout' },
    ]);

    const navbarItems = ref([
      { title: 'My Dashboard', to: '/dashboard', icon: 'mdi-home-account' },
      { title: 'New CareerFit', to: '/search', icon: 'mdi-briefcase-search' },
      { title: 'Saved CareerFits', to: '/saved', icon: 'mdi-bookmark-box' },
      { title: 'My Coaching', to: '/coaching', icon: 'mdi-school' },
      { title: 'Feedback', icon: 'mdi-comment-text' },
    ]);

    const feedbackOverlay = async () => {
      showOverlay.value = true;
      console.log('Feedback button clicked in feedbackOverlay method');
    };

    const handleItemClick = (title) => {
      console.log(`handleItemClick triggered with title: ${title}`);
      if (title === 'Feedback') {
        feedbackOverlay();
      }
    };

    const updateTab = (value) => {
      isFeedbackActive.value = value === 'feedback';
    };

    watch(activeTab, (newValue) => {
      updateTab(newValue);
    });

    updateTab(activeTab.value);

    const submitFeedback = async () => {
      loading.value = true; // Start loading
      const feedbackData = {
        satisfactionLevel: satisfactionLevel.value,
        feedbackText: feedbackText.value,
        firstName: userdata.value.firstName, // Append firstName from userdata
        lastName: userdata.value.lastName    // Append lastName from userdata
      };
      await putData(feedbackData);
      loading.value = false; // Stop loading
    };

    const submitBugReport = async () => {
      loading.value = true; // Start loading
      const bugReportData = {
        bugLocation: bugLocation.value,
        bugDescription: bugDescription.value,
        firstName: userdata.value.firstName, // Append firstName from userdata
        lastName: userdata.value.lastName    // Append lastName from userdata
      };
      await putData(bugReportData);
      loading.value = false; // Stop loading
    };

    const putData = async (data) => {
      try {
        await instance.put('/feedback', { 
          feedback : data
        });
        console.log('Data successfully submitted:', data);
        feedbackSuccess.value = true; // Set feedbackSuccess to true on success
      } catch (error) {
        console.error('Error submitting data:', error);
        feedbackSuccess.value = false; // Ensure feedbackSuccess is false on error
      }
    };

    const drawer = ref(false);
    const rail = ref(true);
    const drawerWidth = ref(60);

    const toggleDrawerWidth = () => {
      console.log('Toggling drawer width');
      drawerWidth.value = drawerWidth.value === 60 ? 220 : 60;
    };

    const toggleRail = () => {
      console.log('Toggling rail');
      rail.value = !rail.value;
      drawer.value = rail.value;
    };

    onMounted(async () => {
      const storedUserdata = sessionStorage.getItem('userdata');

      if (storedUserdata) {
        const parsedUserdata = JSON.parse(storedUserdata);
        userdata.value = parsedUserdata;
        firstName.value = userdata.value.firstName;
        lastInitial.value = userdata.value.lastName ? userdata.value.lastName[0] : '';
        userdataLoaded.value = true;
      } else {
        try {
          const response = await instance.get('profile', {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          userdata.value = response.data;
          userStore.setSessionUserData(userdata.value);

          const cf_search = {
            selectedJobFamilies: userdata.value.cf_search['selectedJobFamilies[]'],
            selectedJobProspects: userdata.value.cf_search['selectedJobProspects[]'],
            salary: Number(userdata.value.cf_search.salary[0]),
            isHourly: userdata.value.cf_search.isHourly[0] === "true",
            includeRegulated: userdata.value.cf_search.includeRegulated[0] === "true",
            timeToFirstJob: Number(userdata.value.cf_search.timeToFirstJob[0])
          };
          userStore.setCfSearch(cf_search);
          userStore.setCfSaved(userdata.value.cf_saved);

          firstName.value = userdata.value.firstName;
          lastInitial.value = userdata.value.lastName ? userdata.value.lastName[0] : '';
          userdataLoaded.value = true;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    });

    return {
      profileItems,
      firstName,
      lastInitial,
      navbarItems,
      drawer,
      drawerWidth,
      toggleDrawerWidth,
      rail,
      toggleRail,
      userdataLoaded,
      showOverlay,
      lastName,
      email,
      message,
      activeTab,
      bugDescription,
      satisfactionLevel,
      bugLocation,
      feedbackText,
      isFeedbackActive,
      feedbackSuccess,
      loading,
      updateTab,
      feedbackOverlay,
      handleItemClick,
      submitFeedback,
      submitBugReport,
      putData
    };
  },
};
</script>
<style scoped>
.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}

.success-icon {
  font-size: 60px;
}

.success-text {
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
}
</style>