<template>
  <div style="min-height: 100vh; display: flex; justify-content: center; align-items: center;">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col class="mx-auto" style="max-width: 450px;">
          <v-card class="elevation-9" max-width="450">
            <v-toolbar color="primary" dark flat elevation="3">
              <v-toolbar-title class="text-center">CareerFit MVP</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-8 py-0">
              <v-form v-if="changePassword" lazy-validation @submit.prevent="submitPasswordChange">
                <v-card-title class="text-h5 font-weight-bold mb-3">Welcome to CareerFit.ca!</v-card-title>
                
                <v-text-field 
                  label="First Name" 
                  v-model="firstName" 
                  prepend-icon="mdi-account" 
                  :rules="requiredFieldRules"
                ></v-text-field>
                
                <v-text-field 
                  label="Last Name" 
                  v-model="lastName" 
                  prepend-icon="mdi-account" 
                  :rules="requiredFieldRules"
                ></v-text-field>
              
                <v-text-field 
                  label="New Password" 
                  v-model="newPassword" 
                  prepend-icon="mdi-lock" 
                  type="password" 
                  :rules="passwordRules"
                ></v-text-field>
                
                <v-text-field 
                  label="Confirm Password" 
                  v-model="confirmPassword" 
                  prepend-icon="mdi-lock" 
                  type="password" 
                  :rules="confirmPasswordRules"
                  class="mb-3"
                ></v-text-field>
                
                <v-messages
                  v-if="errorMessage"
                  :value="[errorMessage]"
                  color="red"
                ></v-messages>
                
                <v-btn :loading="loading" block variant="tonal" large rounded="lg" color="primary" type="submit" :disabled="isButtonDisabled">
                  Change Password
                </v-btn>
              </v-form>
              <v-form v-else-if="resetPassword" lazy-validation @submit.prevent="onResetPasswordButtonClick">
                <v-text-field 
                  label="Email" 
                  v-model="email" 
                  prepend-icon="mdi-email" 
                  :rules="[v => !!v || 'Email is required']"
                ></v-text-field>
                <v-text-field 
                  label="Code" 
                  v-model="code" 
                  prepend-icon="mdi-lock" 
                  :rules="[v => !!v || 'Code is required']"
                ></v-text-field>
                <v-text-field 
                  label="New Password" 
                  v-model="newPassword" 
                  prepend-icon="mdi-lock" 
                  type="password" 
                  :rules="passwordRules"
                ></v-text-field>
                <v-text-field 
                  label="Confirm Password" 
                  v-model="confirmPassword" 
                  prepend-icon="mdi-lock" 
                  type="password" 
                  :rules="confirmPasswordRules"
                  class="mb-3"
                ></v-text-field>
                <v-messages
                  v-if="errorMessage"
                  :value="[errorMessage]"
                  color="red"
                ></v-messages>
                <v-btn class="mb-4" :loading="loading" block variant="tonal" large rounded="lg" color="primary" :disabled="isSendCodeDisabled" @click="onSendCodeButtonClick">Send Code</v-btn>
                <v-btn :loading="loading" block variant="tonal" large rounded="lg" color="primary" type="submit" :disabled="isButtonDisabled">Reset Password</v-btn>
              </v-form>
              <v-form v-else @submit.prevent="submit">
              <v-text-field 
                label="Username" v-model="username" 
                :rules="[v => !!v || 'Username is required']"
                prepend-icon="mdi-account" 
                variant="solo-filled">
              </v-text-field>
              <v-text-field 
                label="Password" v-model="password" 
                :rules="[v => !!v || 'Password is required']"
                prepend-icon="mdi-lock" 
                type="password" 
                variant="solo-filled">
              </v-text-field>
              <p v-if="errorMessage" class="error mt-1">{{ errorMessage }}</p>
              <v-btn :loading="loading" block variant="tonal" large rounded="lg" color="primary" type="submit">Login</v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions class="my-0 py-0">
              <v-spacer></v-spacer>
              <v-btn class="right" size="small" text color="primary" @click="activateResetPassword" v-if="authFailed">Forgot password?</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref , watch, computed} from 'vue';
import { jwtDecode } from 'jwt-decode';
import { login, forcechangePasswordSubmit, sendCode, resetPasswordSubmit } from '../store/auth2';
import router from '../router/router';
import { updateAuthorizationToken } from '../store/axios';

//YDO|1Zny
export default {
  setup() {
      const username = ref('');
      const password = ref('');
      const errorMessage = ref('');
      const changePassword = ref(false);
      const newPassword = ref('');
      const confirmPassword = ref('');
      const sessionUserAttributes = ref(null);
      const cognitoUserRef = ref(null);
      const loading = ref(false);
      const authFailed = ref(false);
      const passwordsMatch = ref(true);
      const resetPassword = ref(false);
      const code = ref('');
      const email = ref('');
      const firstName = ref(''); // New field
      const lastName = ref('');  // New field

      let cognitoUser; // Declare cognitoUser with let so it can be reassigned

      const activateResetPassword = () => {
        resetPassword.value = true;
        authFailed.value = false;
      };
      const requiredFieldRules = [v => !!v || 'This field is required'];
      const passwordRules = [v => !!v || 'Enter new password'];
      const confirmPasswordRules = [
        v => !!v || 'Please confirm password',
        () => passwordsMatch.value || 'Passwords do not match',
      ];

      watch([newPassword, confirmPassword], () => {
        passwordsMatch.value = newPassword.value === confirmPassword.value;
      }, { immediate: true });

      const isButtonDisabled = computed(() => {
        return !newPassword.value || !confirmPassword.value || !passwordsMatch.value;
      });

      const isSendCodeDisabled = computed(() => {
        return !email.value
      });

      async function loginSuccess(cognitoUserSession) {
        console.log('CognitoUserSession:', cognitoUserSession);
        const idToken = cognitoUserSession.getIdToken().getJwtToken();
        console.log('ID Token:', idToken);
        await updateAuthorizationToken(idToken);

          const redirectTo = router.currentRoute.value.query.redirect ? router.currentRoute.value.query.redirect.toString() : '/dashboard';
          router.replace(redirectTo);
      }

    async function submit() {
      loading.value = true;
      if (!username.value || !password.value) {
        loading.value = false;
        return;
      }

      try {
        const { status, result, cognitoUser, userAttributes } = await login(username.value, password.value);
        cognitoUserRef.value = cognitoUser;
        if (status === 'success') {
          const accessToken = result.getAccessToken().getJwtToken();
          console.log('ACCESS TOKEN', jwtDecode(accessToken));
          loginSuccess(result);
        } 
        else if (status === 'newPasswordRequired') {
          console.log('login - new password required', userAttributes, cognitoUser);
          if (userAttributes) {
            delete userAttributes.email_verified;
            delete userAttributes.phone_number_verified;
            sessionUserAttributes.value = { ...userAttributes };
            delete sessionUserAttributes.value.email;
          }
          changePassword.value = true;
        }
        else {
          errorMessage.value = 'Your username or password is incorrect.';
          authFailed.value = true;
        }
      }
      catch ({ error, cognitoUser }) {
        cognitoUserRef.value = cognitoUser;
        console.log('onFailure called');
        loading.value = false;
        errorMessage.value = error ? (error.message || JSON.stringify(error)) : 'An unknown error occurred';
        authFailed.value = true;
      }
      finally {
        loading.value = false;
      }
    }

    function submitPasswordChange() {
      loading.value = true;
      console.log('cognitoUser in submitPasswordChange:', cognitoUserRef.value);
      const updatedAttributes = {
        ...sessionUserAttributes.value,
        given_name: firstName.value,
        family_name: lastName.value,
      };

      forcechangePasswordSubmit(cognitoUserRef.value, newPassword.value, updatedAttributes)
        .then(() => {
          loading.value = false;
          window.location.reload();
        })
        .catch((error) => {
          loading.value = false;
          console.error(error);
        });
    }

    function onSendCodeButtonClick() {
      sendCode(cognitoUserRef.value);
    }

    function onResetPasswordButtonClick() {
      resetPasswordSubmit(cognitoUserRef.value, code.value, newPassword.value)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    }

      

      return {
          username,
          password,
          errorMessage,
          changePassword,
          newPassword,
          confirmPassword,
          passwordRules,
          confirmPasswordRules,
          isButtonDisabled,
          isSendCodeDisabled,
          sessionUserAttributes,
          cognitoUser,
          submit,
          submitPasswordChange,
          loading,
          authFailed,
          resetPassword,
          code,
          activateResetPassword,
          onResetPasswordButtonClick,
          onSendCodeButtonClick,
          email,
          firstName, // Include these in return
          lastName,  // Include these in return
          requiredFieldRules,
      }
  }
}

</script>
<style scoped>
.error {
  color: rgb(168, 37, 37);
  text-align: center;
  font-size: 0.8em;
}

</style>